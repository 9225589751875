import { InlineEntry } from 'components/elements/RichTextPageContent/types'
import arrayHasValue from 'utils/arrayHasValue'

function createMap<T extends InlineEntry | Contentful.Asset | Contentful.IComponentMediaWrapper>(
  links: T[],
): Map<string, T> {
  const map = new Map()
  const items = links?.filter((_) => !!_?.sys?.id)
  if (arrayHasValue(items)) {
    for (const item of items) {
      map.set(item.sys.id, item)
    }
  }
  return map
}

export default createMap
