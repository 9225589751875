import {
  Text,
  Heading,
  Td,
  Tr,
  Th,
  Tbody,
  Table,
  UnorderedList,
  OrderedList,
  ListItem,
  Box,
  Link,
} from '@chakra-ui/react'
import { documentToReactComponents, Options, RenderMark } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, Document, INLINES, Text as ContentfulText } from '@contentful/rich-text-types'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'

import Image from 'components/elements/Image'
import { AssetLinks, InlineEntry } from 'components/elements/RichTextPageContent/types'
import createMap from 'components/elements/RichTextPageContent/utils/createMap'

const DynamicVideoEmbed = dynamic(() => import('components/elements/VideoEmbedded'))

export const defaultRichTextStyles = {
  sx: {
    '*:not(.embeddedBlock)': {
      maxW: 'main-block',
    },
    '*.embeddedBlock *': {
      maxW: 'full',
    },
  },
} as const

const styles = {
  heading: {
    mt: '6 !important',
  },
  caption: { mt: 4, noOfLines: 2, fontSize: 'xs', color: 'secondary.grey.900' },
  table: {
    wrapper: { maxW: '100%', overflowX: 'auto' },
    sx: {
      td: {
        borderColor: 'primary.grey.100',
        fontSize: '1rem',
        px: 4,
        _first: { pl: 0 },
      },
      th: {
        borderColor: 'primary.grey.100',
        borderBottomWidth: '4px',
        color: 'primary.black',
        fontSize: { base: 'md', lg: 'xl' },
        lineHeight: 1,
        textTransform: 'none',
        pb: 6,
        px: 4,
        _first: { pl: 0 },
      },
    },
  },
  paragraph: {
    mb: '6 !important',
    sx: { a: { color: 'red', _hover: { textDecor: 'underline' } } },
  },
} as const

export const options = (
  links: AssetLinks,
  resolveLink: (id: string | undefined) => string,
  getRoute: (route: string, locale?: string | undefined) => string,
): Options => {
  const embeddedAssetMap = createMap<Contentful.Asset>(links?.assets?.block)
  const hyperlinkAssetMap = createMap<Contentful.Asset>(links?.assets?.hyperlink)
  const inlineEntryMap = createMap<InlineEntry>(links?.entries?.inline)
  const blockEntryMap = createMap<Contentful.IComponentMediaWrapper>(links?.entries?.block)

  const renderMark: RenderMark = {
    [MARKS.BOLD]: (text) => <Text as="b">{text}</Text>,
  }

  return {
    renderMark: renderMark,
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) =>
        children?.toString().trim() === '' ? null : <Text {...styles.paragraph}>{children}</Text>,
      [BLOCKS.HEADING_1]: (_node, children): JSX.Element => (
        <Heading as="h1" size="xl" {...styles.heading}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (_node, children): JSX.Element => (
        <Heading as="h2" size="lg" {...styles.heading}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (_node, children): JSX.Element => (
        <Heading as="h3" size="md" {...styles.heading}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (_node, children): JSX.Element => (
        <Heading as="h4" size="sm" {...styles.heading}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (_node, children): JSX.Element => (
        <Heading as="h5" size="xs" {...styles.heading}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_6]: (_node, children): JSX.Element => (
        <Heading as="h6" size="xxs" {...styles.heading}>
          {children}
        </Heading>
      ),
      [BLOCKS.UL_LIST]: (_node, children): JSX.Element => <UnorderedList variant="dashed">{children}</UnorderedList>,
      [BLOCKS.OL_LIST]: (_node, children): JSX.Element => <OrderedList pl="5">{children}</OrderedList>,
      [BLOCKS.LIST_ITEM]: (node, _children): JSX.Element => {
        const transformedChildren = documentToReactComponents(node as Document, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.LIST_ITEM]: (_node, children) => children,

            [INLINES.HYPERLINK]: (node) => {
              const linkText = (node.content?.[0] as ContentfulText)?.value
              return linkText ? (
                <Link as={NextLink} href={node.data?.uri}>
                  {linkText}
                </Link>
              ) : null
            },
            [INLINES.ENTRY_HYPERLINK]: (node) => {
              const linkText = (node.content?.[0] as ContentfulText)?.value
              return linkText ? (
                <Link as={NextLink} href={resolveLink(node.data.target.sys.id)}>
                  {linkText}
                </Link>
              ) : null
            },
            [INLINES.ASSET_HYPERLINK]: (node) => {
              const asset = hyperlinkAssetMap.get(node?.data?.target?.sys?.id)
              const linkText = (node.content?.[0] as ContentfulText)?.value
              return asset?.url && linkText ? (
                <Link as={NextLink} href={asset.url}>
                  {linkText}
                </Link>
              ) : null
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
              const entry = inlineEntryMap.get(node.data.target.sys.id)
              if (!entry) {
                return null
              }
              const href = entry.__typename === 'PageHome' ? getRoute('home') : resolveLink(node.data.target.sys.id)
              return href ? (
                <Link as={NextLink} href={href} target="_blank" rel="noreferrer">
                  {entry.heading}
                </Link>
              ) : (
                <Text>{entry.heading}</Text>
              )
            },
          },
        })

        return <ListItem>{transformedChildren}</ListItem>
      },
      [BLOCKS.TABLE]: (_node, children): JSX.Element => (
        <Box {...styles.table.wrapper}>
          <Table sx={styles.table.sx}>
            <Tbody>{children}</Tbody>
          </Table>
        </Box>
      ),
      [BLOCKS.TABLE_ROW]: (_node, children): JSX.Element => <Tr>{children}</Tr>,
      [BLOCKS.TABLE_CELL]: (node, _children): JSX.Element => {
        const transformedChildren = documentToReactComponents(node as Document, {
          renderMark: renderMark,
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.TABLE_CELL]: (_node, children) => children,
          },
        })
        return <Td>{transformedChildren}</Td>
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, _children): JSX.Element => {
        const transformedChildren = documentToReactComponents(node as Document, {
          renderMark: renderMark,
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => children,
          },
        })
        return <Th>{transformedChildren}</Th>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = embeddedAssetMap.get(node?.data?.target?.sys?.id)
        if (asset?.contentType.startsWith('image/') && asset?.url) {
          return (
            <>
              <Image
                src={asset.url}
                alt={asset.title}
                blurDataURL={asset.blurDataURL}
                width={{ base: 322, sm: 460, md: 668, lg: asset.width }}
                height={{ base: 200, sm: 300, md: 400, lg: asset.height }}
                style={{ height: 'auto', marginTop: '24px' }}
              />
              <Text {...styles.caption}>{asset?.title}</Text>
            </>
          )
        } else {
          return null
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = blockEntryMap.get(node.data.target.sys.id)
        if (!entry) {
          return null
        }

        const mediaType = entry.mediaType?.[0]
        if (!mediaType) {
          return null
        }

        switch (mediaType) {
          case 'Image':
            return entry.image?.url ? (
              <>
                <Image
                  src={entry.image.url}
                  alt={entry.caption || ''}
                  width={{ base: 322, md: 668, lg: entry.isFullWidth ? 1360 : 668 }}
                  height={{ base: 200, md: 400, lg: entry.isFullWidth ? 680 : 400 }}
                  maxW="100% important"
                  style={{ marginTop: '24px' }}
                  className="embeddedBlock"
                />
                {entry.caption && <Text {...styles.caption}>{entry.caption}</Text>}
              </>
            ) : null
          case 'Video':
            return entry.vimeoId ? (
              <DynamicVideoEmbed
                vimeoId={entry.vimeoId}
                title={entry.caption}
                caption={entry.caption}
                maxWidth={entry.isFullWidth ? 'desktop-content' : 'main-block'}
              />
            ) : null
          default:
            return null
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const linkText = (node.content?.[0] as ContentfulText)?.value
        return linkText ? <NextLink href={resolveLink(node.data.target.sys.id)}>{linkText}</NextLink> : null
      },
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const asset = hyperlinkAssetMap.get(node?.data?.target?.sys?.id)
        const linkText = (node.content?.[0] as ContentfulText)?.value
        return asset?.url && linkText ? (
          <NextLink href={asset.url} target="_blank">
            {linkText}
          </NextLink>
        ) : null
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const entry = inlineEntryMap.get(node.data.target.sys.id)
        if (!entry) {
          return null
        }

        let href
        if (entry.__typename === 'PageHome') {
          href = getRoute('home')
        } else {
          href = resolveLink(node.data.target.sys.id)
        }

        return href ? <NextLink href={href}>{entry.heading}</NextLink> : <Text>{entry.heading}</Text>
      },
    },

    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  }
}
