import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useStyleConfig,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'
import { Control, FieldErrors, FieldValues } from 'react-hook-form'

export type FormInputProps = Omit<FormControlProps, 'label' | 'onChange'> & {
  name: string
  label?: ReactNode
  help?: string
  icon?: string
  errors?: FieldErrors<FieldValues>
  errorHint?: boolean
  isInline?: boolean
  useHelpIcon?: boolean
  variant?: string
  control?: Control
  isRequired?: boolean
}

const FormInput = forwardRef<FormInputProps, 'input'>(
  (
    {
      label,
      name,
      icon,
      id = name,
      type = 'text',
      errors,
      help,
      defaultValue,
      placeholder,
      isInvalid,
      errorHint = false,
      isInline = false,
      as: Component = Input,
      size,
      children,
      value,
      onChange,
      control,
      variant,
      fontSize,
      autoFocus,
      isRequired,
      autoComplete,
      inputMode,
      enterKeyHint,
      ...props
    },
    ref,
  ) => {
    const styleConfig = useStyleConfig('FormInput', { variant })
    const isFoundInError = name in (errors ?? {})
    const hasError = isFoundInError || isInvalid

    const { t } = useTranslation()

    return (
      <FormControl variant="floating" id={id} isInvalid={hasError} {...(isInline && { d: 'flex' })} {...props}>
        <Stack spacing={1} w="full">
          <InputGroup>
            <Component
              name={name}
              type={type}
              size={size}
              defaultValue={defaultValue}
              placeholder={placeholder}
              ref={ref}
              value={value}
              onChange={onChange}
              control={control}
              sx={styleConfig}
              fontSize={fontSize}
              isInvalid={hasError}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              inputMode={inputMode}
              enterKeyHint={enterKeyHint}
              icon={icon}
            >
              {children}
            </Component>
            {typeof label === 'string' ? <FormLabel sx={styleConfig}>{label}</FormLabel> : label}
            {isRequired && (
              <InputRightElement fontSize="lg" pt={3} color="primary.red">
                *
              </InputRightElement>
            )}
          </InputGroup>
          {help && (
            <FormHelperText id={`${id}-helper-text`} display="flex">
              {help}
            </FormHelperText>
          )}
          {!errorHint && errors && (
            <FormErrorMessage>
              {(errors[name]?.message as string) ?? t('components.contactForm.success.title')}
            </FormErrorMessage>
          )}
        </Stack>
      </FormControl>
    )
  },
)

export default FormInput
