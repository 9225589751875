import { Alert, Stack, AlertProps } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import { getAlertIcon } from './utils/getAlertIcon'

const TAG = 'CardAlert'

const CardAlert: FunctionComponent<AlertProps> = ({ variant = 'success', children, ...props }) => {
  return (
    <Alert variant={variant} py="5" alignItems="flex-start" {...props}>
      {getAlertIcon(variant)}
      <Stack align="flex-start">{children}</Stack>
    </Alert>
  )
}

CardAlert.displayName = TAG

export default CardAlert
