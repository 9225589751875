import { AlertProps, Icon } from '@chakra-ui/react'
import React from 'react'

import CheckIcon from 'assets/check.svg?component'

export const getAlertIcon = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'success':
      return <Icon as={CheckIcon} boxSize={4} mr="4" my="1" aria-hidden={true} />

    default:
      return null
  }
}
