import { StackProps, Stack } from '@chakra-ui/react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { NodeData } from '@contentful/rich-text-types'

import { options } from 'components/elements/RichTextPageContent/config'
import useRoutePaths from 'hooks/useRoutePaths'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'

const TAG = 'RichTextPageContent'

type Props = StackProps & { richTextBodyField: NodeData | undefined }

const RichTextPageContent = ({ richTextBodyField, ...props }: Props) => {
  const resolveLink = useResolveDynamicPage('')
  const getRoute = useRoutePaths()

  if (!richTextBodyField?.json) {
    return null
  }

  return (
    <Stack data-contentful-field-id="primaryRichText" {...props}>
      {documentToReactComponents(richTextBodyField.json, options(richTextBodyField.links, resolveLink, getRoute))}
    </Stack>
  )
}

RichTextPageContent.displayName = TAG

export default RichTextPageContent
